export enum SectorEnum {
  IT = "IT",
  INDUSTRIAL = "INDUSTRIAL",
  TELECOMMUNICATIONS = "TELECOMMUNICATIONS",
  SME_BIG_CORPORATIONS = "SME_BIG_CORPORATIONS",
  STREET_LIGHTING = "STREET_LIGHTING",
  PHOTOVOLTAIC_ENERGY_ENERGY_EFFICIENCY = "PHOTOVOLTAIC_ENERGY_ENERGY_EFFICIENCY",
  PUBLIC_ADMINISTRATION = "PUBLIC_ADMINISTRATION",
  OTHERS = "OTHERS"
}
  